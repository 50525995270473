<script>
export default {
  metaInfo: {
    title: "My Projects",
  }
};
</script>

<script setup>
import PhpServiceTableView from '@/components/basic/php-wrappers/PhpServiceTableView.vue';
</script>

<template>
  <PhpServiceTableView url="?f=administration&f2=projectAdministration"/>
</template>

<style scoped>

</style>